<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ $t("labels.employee") }}</span>
      <v-spacer></v-spacer>
      <v-btn
        color="purple"
        outlined
        class="mr-2"
        @click="showDialogConfigTimekeeping"
        small
        v-if="checkModule(['office_timekeeping'])"
      >
        {{ $t("labels.timekeeping_config") }}
      </v-btn>
      <v-btn
        color="warning"
        outlined
        class="mr-2"
        @click="showDialogChangePassword"
        small
      >
        {{ $t("labels.change_password") }}
      </v-btn>
      <v-btn color="success" outlined class="mr-2" @click="submitDialog" small>
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" outlined @click="closeDialog" small>
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <div class="mb-1">
              <v-carousel v-model="imgCarousel" height="216px">
                <v-carousel-item v-for="img in imgTypes" :key="img">
                  <template v-slot:default>
                    <img
                      style="
                        max-width: 100%;
                        height: auto;
                        object-fit: cover;
                        aspect-ratio: 1 / 1;
                      "
                      v-viewer
                      :src="detail[img] || require('@/assets/user.png')"
                      alt=""
                    />
                  </template>
                </v-carousel-item>
              </v-carousel>
            </div>
            <div class="text-center">
              <v-btn
                x-small
                color="warning"
                @click="$refs.inputUploadFile.click()"
              >
                {{ $t("labels.change") }}
              </v-btn>
              <input
                type="file"
                ref="inputUploadFile"
                accept="image/*"
                @change="onInputFileChange"
                class="d-none"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="9">
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  :label="$t('labels.full_name')"
                  v-model="detail.full_name"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  :label="$t('labels.account')"
                  v-model="detail.email"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="detail.id_warehouses"
                  :items="warehouses"
                  :label="$t('labels.warehouse_1')"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="detail.id_material_warehouses"
                  :items="material_warehouses"
                  :label="$t('labels.material_warehouse')"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="detail.id_poss"
                  :items="poss"
                  :label="$t('labels.pos')"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="detail.id_department"
                  :items="departments"
                  :label="$t('labels.department')"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="detail.id_position"
                  :items="positions"
                  :label="$t('labels.position')"
                  dense
                  outlined
                  hide-details
                  class="c-input-small"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <div class="mb-3"></div>
            <!--<div class="mb-3">
                          <v-autocomplete v-model="detail.permissions" :items="permissions"
                                          label="Phân quyền" dense multiple></v-autocomplete>
                        </div>-->
          </v-col>
        </v-row>
      </v-container>
      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
    </v-card-text>
    <v-card-title>
      <span class="text-h5" v-html="$t('labels.set_ssc_permission')"></span>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="permission in permissions"
          :key="`permission_${permission.value}`"
        >
          <v-checkbox
            dense
            hide-details
            v-model="detail.permissions"
            :value="permission.value"
            :label="permission.text"
            multiple
            class="c-input-small"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title v-if="material_warehouses.length > 0">
      <span
        class="text-h5"
        v-html="$t('labels.set_material_warehouse_permission')"
      ></span>
    </v-card-title>
    <v-card-text v-if="material_warehouses.length > 0">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="permission in materialWarehousePermissions"
          :key="`material_warehouse_permission_${permission.value}`"
        >
          <v-checkbox
            dense
            hide-details
            v-model="detail.permissions"
            :value="permission.value"
            :label="permission.text"
            multiple
            class="c-input-small"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title v-if="isShowPosConfig()">
      <span class="text-h5" v-html="$t('labels.set_pos_permission')"></span>
    </v-card-title>
    <v-card-text v-if="isShowPosConfig()">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="permission in posPermissions"
          :key="`pos_permission_${permission.value}`"
        >
          <v-checkbox
            dense
            hide-details
            v-model="detail.permissions"
            :value="permission.value"
            :label="permission.text"
            multiple
            class="c-input-small"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title>
      <span
        class="text-h5"
        v-html="$t('labels.set_administration_permission')"
      ></span>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="permission in administrationPermissions"
          :key="`administration_permission_${permission.value}`"
        >
          <v-checkbox
            dense
            hide-details
            v-model="detail.permissions"
            :value="permission.value"
            :label="permission.text"
            multiple
            class="c-input-small"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-title>
      <span
        class="text-h5"
        v-html="$t('labels.set_internal_request_permission')"
      ></span>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="permission in requestPermissions"
          :key="`request_permission_${permission.value}`"
        >
          <v-checkbox
            dense
            hide-details
            v-model="detail.permissions"
            :value="permission.value"
            :label="permission.text"
            multiple
            class="c-input-small"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <template v-if="checkModule(['administration_invoice'])">
      <v-card-title>
        <span
          class="text-h5"
          v-html="$t('labels.set_invoice_permission')"
        ></span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="permission in invoicePermissions"
            :key="`invoice_permission_${permission.value}`"
          >
            <v-checkbox
              dense
              hide-details
              v-model="detail.permissions"
              :value="permission.value"
              :label="permission.text"
              multiple
              class="c-input-small"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <v-dialog v-model="dialogChangePassword" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h6 mb-3">{{
          $t("labels.change_password")
        }}</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="password"
            class="c-input-xs mb-3"
            :label="$t('labels.password')"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            autofocus
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogChangePassword">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="resetPassword"
            :disabled="!password || password.length < 6"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfigTimekeeping" persistent max-width="600px">
      <EmployeeConfig
        v-if="dialogConfigTimekeeping"
        :employee="employee"
        @cancel="hideDialogConfigTimekeeping"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
import { compress } from "image-conversion";
import {
  CUSTOMER_PERMISSIONS,
  CUSTOMER_MATERIAL_WAREHOUSE_PERMISSIONS,
  CUSTOMER_POS_PERMISSIONS,
  CUSTOMER_ADMINISTRATION_PERMISSIONS,
  INTERNAL_REQUEST_PERMISSIONS,
  INVOICE_PERMISSIONS,
} from "@/libs/const";

export default {
  name: "DialogDetailSubAccount",
  components: {
    EmployeeConfig: () => import("@/components/timekeeping/EmployeeConfig"),
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
    positions: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    detail: {},
    errMsg: null,
    warehouses: [],
    material_warehouses: [],
    poss: [],
    devices: [],
    password: null,
    passwordShow: false,
    dialogChangePassword: false,
    dialogConfigTimekeeping: false,
    permissions: [],
    posPermissions: [],
    materialWarehousePermissions: [],
    administrationPermissions: [],
    requestPermissions: [],
    invoicePermissions: [],
    filePath: null,
    imgCarousel: 0,
    imgTypes: ["image_portrait", "image_idcard_front", "image_idcard_back"],
  }),
  computed: {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      if (files && files[0]) {
        const filePath = await this.uploadFile(files[0]);
        if (filePath) {
          this.detail[
            this.imgTypes[this.imgCarousel]
          ] = `${process.env.VUE_APP_FILE_CDN_URL}/${filePath}`;
        }
        this.$refs.inputUploadFile.value = null;
      }
      this.isLoading = false;
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
    async submitDialog() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/update-sub-account", this.detail);
        this.closeDialog();
        this.refreshData();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    getDetailEmployee() {
      httpClient
        .post("/sub-account-detail", {
          id: this.employee.id,
        })
        .then(({ data }) => {
          const employee = { ...data.employee };
          employee.id_warehouses = (data.warehouses || []).map((i) => i.id);
          employee.id_material_warehouses = (
            data.material_warehouses || []
          ).map((i) => i.id);
          employee.id_poss = (data.poss || []).map((i) => i.id);
          employee.permissions = [...data.permissions].map((i) => i.item_name);
          this.detail = { ...employee };
        });
    },
    async getWarehouses() {
      const { warehouses, material_warehouses, poss } = window.me;
      this.warehouses = [...warehouses].map((w) => ({
        value: w.id,
        text: w.code_name || "",
      }));
      this.material_warehouses = [...material_warehouses].map((w) => ({
        value: w.id,
        text: w.code || "",
      }));
      this.poss = [...poss].map((p) => ({
        value: p.id,
        text: p.code || "",
      }));
    },
    async getPermissions() {
      this.permissions = [...CUSTOMER_PERMISSIONS].map((e) => ({
        value: e,
        text: this.$t(`labels.${e}_permission`),
      }));
      this.posPermissions = [...CUSTOMER_POS_PERMISSIONS].map((e) => ({
        value: e,
        text: this.$t(`labels.${e}_permission`),
      }));
      this.materialWarehousePermissions = [
        ...CUSTOMER_MATERIAL_WAREHOUSE_PERMISSIONS,
      ].map((e) => ({
        value: e,
        text: this.$t(`labels.${e}_permission`),
      }));
      this.administrationPermissions = [
        ...CUSTOMER_ADMINISTRATION_PERMISSIONS,
      ].map((e) => ({
        value: e,
        text: this.$t(`labels.${e}_permission`),
      }));
      this.requestPermissions = [...INTERNAL_REQUEST_PERMISSIONS].map((e) => ({
        value: e,
        text: this.$t(`labels.${e}_permission`),
      }));
      this.invoicePermissions = [...INVOICE_PERMISSIONS].map((e) => ({
        value: e,
        text: this.$t(`labels.${e}_permission`),
      }));
    },
    showDialogChangePassword() {
      this.dialogChangePassword = true;
    },
    hideDialogChangePassword() {
      this.dialogChangePassword = false;
    },
    showDialogConfigTimekeeping() {
      this.dialogConfigTimekeeping = true;
    },
    hideDialogConfigTimekeeping() {
      this.dialogConfigTimekeeping = false;
    },
    async resetPassword() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/update-sub-account-password", {
          password: this.password,
          id: this.detail.id,
        });
        this.hideDialogChangePassword();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    isShowPosConfig() {
      const customer = window.me && window.me.customer;
      return customer.is_active_pos;
    },
  },
  mounted() {
    this.getDetailEmployee();
    this.getWarehouses();
    this.getPermissions();
  },
};
</script>

<style scoped lang="scss">
.permission-col {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  position: relative;
}
</style>
